<!--
 * @Description: 首页
 * @Author: luocheng
 * @Date: 2021-09-13 13:55:25
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2024-01-12 18:51:07
-->
<template>
	<div class="index" v-loading="loading || globalLoading">
		<Parser v-if="inited"></Parser>
	</div>
</template>

<script>
import { getRenderData } from '@/apis/data/index';
import Parser from '@/components/parser/Index';
import { isJSONStr } from '@/utils/tools';
import eventBus from '@/plugins/eventBus';

export default {
	name: 'Index',
	components: {
		Parser
	},
	props: {
    pageUUID: {
      type: String,
      required: false,
      default: ''
    }
  },
	data() {
		return {
			inited: false,
			loading: false,
			globalLoading: false
		};
	},
	created() {
		this.getViewData();
	},
	mounted() {
		eventBus.$on('dataviewGlobalLoading', ({ loading }) => {
			this.globalLoading = loading;
		});
	},
	watch: {
		$route() {
			this.reRender();
		}
	},
	methods: {
		/**
		 * @desc: 获取dataview配置数据
		 */
		getViewData() {
			this.loading = true;
			getRenderData({
				__method_name__: 'dataList',
				page_uuid: this.pageUUID || this.$route.query.pageUUID,
				object_uuid: 'a4f016d6-c602-4492-8874-f088c3c0b3b9',
				view_uuid: 'view61b951c6a8186',
				transcode: 0
			})
				.then((res) => {
					this.loading = false;
					const { page_data } = res?.data?.data?.[0] || {};
					const { componentData, editorConfig, editorType, globalInterval = {},  statusConfig = [] } = isJSONStr(page_data) ? JSON.parse(page_data) : page_data
					this.$store.commit('setGlobalInterval', globalInterval);
          sessionStorage.setItem('statusConfig', JSON.stringify(statusConfig));
					this.$store.commit('setConfigData', {
						componentData,
						editorConfig,
						editorType
					});
					this.inited = true;
				})
				.catch(() => {
					this.loading = false;
				});
		},
		/**
		 * @desc: 重新渲染
		 */
		reRender() {
			// console.log('重新渲染');
			this.inited = false;
			this.getViewData();
		}
	},
	beforeDestroy() {
		this.globalLoading = false;
		eventBus.$off('dataviewGlobalLoading');
	}
};
</script>

<style lang="less" scoped>
.index {
	width: 100vw;
  height: 100vh;
	overflow: hidden;
  background: var(--systemBackground);
}
</style>